import Image from 'next/image';
import React from 'react';
import { publicS3Bucket } from '../utils/envConfig';

function Reference ( { imagePath} )
{
  return (
    <div className='xl:mx-2 relative w-40 h-40'>
      {/* <img src={ imagePath } className='w-full h-full absolute object-contain object-center' style={ {
        filter: "saturate(0) brightness(0)",
      } } /> */}
      <Image
       src={`https://${publicS3Bucket}/${imagePath}`}
       alt={`logo-${imagePath}`}
       layout='fill'
       objectFit='contain'
       objectPosition='center'
       style={{
        filter: "saturate(0) brightness(0)"
       }}
      />
   
    </div>
  );
}

export default Reference;