import React, { useRef } from "react";
import Reference from "./Reference";
import Carousel from "react-elastic-carousel";

const References = ({ developers }) =>
{
    const carouselRef = useRef(  );
    let resetTimeout;
    const breakPoints = [
        { width: 1, itemsToShow: 2 },
        { width: 550, itemsToShow: 3 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 6 },
    ];

    return (
        <div className="relative  bg-white mx-auto container 3xl:max-w-[1500px]">
            <div className="flex justify-start mb-5">
                <h6 className="text-navy font-Lexend mx-10 mt-6">
                    <span className="font-bold 3xl:pl-0 4xs:text-2xl 3xl:text-3xl ">
                        Official Partner </span>

                    <span className="4xs:text-2xl 3xl:text-3xl"> { "" }of</span>
                </h6>
            </div>
            <div className="flex md:justify-start justify-center lg:my-10  container mx-auto max-w-[1570px] xl:max-w-[1199px] 3xl:max-w-[1500px]">
                {/* <Carousel breakPoints={breakPoints} enableAutoPlay={true} showArrows={false} pagination={false} autoPlaySpeed={2000} transitionMs={2000} ref={ref => (Carousel = ref)}> */ }
                <Carousel
                    breakPoints={ breakPoints }
                    enableAutoPlay={ true }
                    showArrows={ false }
                    pagination={ false }
                    autoPlaySpeed={ 1500 }
                    transitionMs={ 2000 }
                    ref={ carouselRef }
                >
                    { developers?.length > 0 && developers?.map( ( e, i ) => ( <Reference key={ i } imagePath={ e.logo } /> ) ) }

                </Carousel>
            </div>
        </div>
    );
};

export default References;
